.game {
  --ball-size: 15vw;
  --ball-holder-size: var(--ball-size);
  --border-size: calc(var(--ball-size) * 0.04);

  position: relative;
  margin-left: 1rem;
  margin-right: 3rem;
}
.ball-holder {
  width: calc(var(--ball-holder-size) + var(--border-size) * 2);
  height: calc(var(--ball-holder-size) + var(--border-size) * 2);
  border: solid var(--border-size) transparent;
  position: relative;
  border-radius: 50%;

  border: solid var(--border-size) var(--border);
  box-shadow: -7px 7px 0 0 var(--shadow-2) !important;
  overflow: hidden;
}

.ball-holder-details {
  margin-left: -10px;
  margin-top: -2.2rem;
}
.ball-scale {
  --ball-size: 100px;
}
.ball {
  user-select: none;
  width: var(--ball-size);
  height: var(--ball-size);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.ball-in-holder {
  position: absolute;
}
.ball > img {
  border-radius: 50%;
  position: relative;
  top: calc(var(--ball-size) * -0.02);
  width: calc(var(--ball-size) * 0.4);
  height: calc(var(--ball-size) * 0.4);
}

@keyframes in-from-left {
  from {
    transform: translateX(-100%) rotate(-90deg);
  }
  to {
    transform: translateX(0) rotate(0deg);
  }
}
@keyframes out-to-right {
  from {
    transform: translateX(0) rotate(0deg);
  }
  to {
    transform: translateX(100%) rotate(90deg);
  }
}

.in-from-left {
  animation-name: in-from-left;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.out-to-right {
  animation-name: out-to-right;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.ball {
  background: #f5f5f5;
  box-shadow: inset calc(var(--ball-size) * 0.03) calc(var(--ball-size) * -0.03) 0 calc(var(--ball-size) * 0.01)
      var(--color-1),
    inset calc(var(--ball-size) * 0.1) calc(var(--ball-size) * -0.1) 0 calc(var(--ball-size) * 0.01) var(--color-2),
    inset 0 0 0 calc(var(--ball-size) * 0.2) var(--color-3);
  border-radius: 50%;
}
.ball::after {
  content: '';
  border-radius: 50%;
  border: solid calc(var(--ball-size) * 0.02) var(--color-3);
  position: absolute;

  width: calc(var(--ball-size) * 0.5);
  height: calc(var(--ball-size) * 0.5);
}
.ball-b {
  --color-1: hsl(52, 85%, 52%);
  --color-2: hsl(52, 85%, 40%);
  --color-3: hsl(52, 85%, 35%);
}
.ball-i {
  --color-1: hsl(123, 85%, 52%);
  --color-2: hsl(123, 85%, 40%);
  --color-3: hsl(123, 85%, 35%);
}
.ball-n {
  --color-1: hsl(183, 85%, 52%);
  --color-2: hsl(183, 85%, 40%);
  --color-3: hsl(183, 85%, 35%);
}
.ball-g {
  --color-1: hsl(284, 85%, 52%);
  --color-2: hsl(284, 85%, 40%);
  --color-3: hsl(284, 85%, 35%);
}
.ball-o {
  --color-1: hsl(0, 85%, 52%);
  --color-2: hsl(0, 85%, 40%);
  --color-3: hsl(0, 85%, 35%);
}

.pattern-board {
  margin-bottom: 5px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(5, 1.5rem);
  grid-template-rows: 3rem 2px repeat(5, 1.5rem);
  gap: 0 0;
  grid-template-areas:
    'header header header header header'
    '_ _ _ _ _'
    'board board board board board'
    'board board board board board'
    'board board board board board'
    'board board board board board'
    'board board board board board';
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background: hsl(197, 40%, 65%);

  .header {
    font-size: 2rem;
    text-transform: uppercase;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .tile-enabled {
    background: hsl(57, 62%, 65%);
  }
  .tile-disabled {
    background: hsl(200, 4%, 65%);
  }
  .position-13 {
    background: hsl(200, 4%, 50%);
  }
}

.emotes-board-big {
  --position-size: 3vw;
}
.emotes-board-small {
  --position-size: 2.5vw;
}

.emotes-board {
  --two-position-size: calc(var(--position-size) * 1.2);

  user-select: none;
  display: grid;
  grid-template-columns: var(--two-position-size) repeat(15, var(--position-size));
  grid-template-rows: repeat(5, var(--two-position-size));
  grid-gap: 1.5rem 0.5rem;
  grid-template-areas:
    'column number number number number number number number number number number number number number number number'
    'column number number number number number number number number number number number number number number number'
    'column number number number number number number number number number number number number number number number'
    'column number number number number number number number number number number number number number number number'
    'column number number number number number number number number number number number number number number number';
  margin-left: 1rem;
  margin-right: 1rem;

  .number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 2.5rem;
    border-radius: 5px;
  }
  .column span {
  }

  .number title {
  }

  .number img {
    width: calc(var(--position-size) - 0.5rem);
  }
  .bp3-popover-wrapper {
  }
}

.tie-emotes-board {
  grid-template-columns: var(--two-position-size) repeat(15, var(--position-size));
  grid-template-rows: var(--two-position-size);
  grid-gap: 0.5rem 0.5rem;
  grid-template-areas: 'column number number number number number number number number number number number number number number number';
}

.uncalled-number {
  opacity: 0.4;
}
.called-number {
}

.shadow {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.4);
}

.center {
  display: flex;
  align-content: center;
  justify-content: center;
}

.game-logo {
  position: absolute;
  margin-left: 20px;
  bottom: 0;
  user-select: none;
  z-index: -10;
}
