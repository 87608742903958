.small-bingo-board {
  --bingo-element-size: 3rem;
  margin: 0;
}
.mini-bingo-board {
  --bingo-element-size: .8rem;
  margin: 0;
}
.regular-bingo-board {
  --bingo-element-size: 2.5rem;
  margin: 4px;
}
.bingo-board {
  --bingo-element-size-header: calc(var(--bingo-element-size) * 0.85);
  user-select: none;
  display: grid;
  grid-gap: calc(var(--bingo-element-size) * 0.1) calc(var(--bingo-element-size) * 0.1);
  grid-template-columns: repeat(5, var(--bingo-element-size));
  grid-template-rows: var(--bingo-element-size-header) 5px repeat(5, var(--bingo-element-size));
  grid-template-areas: 'header header header header header' '_ _ _ _ _' 'board board board board board' 'board board board board board' 'board board board board board' 'board board board board board' 'board board board board board';
  font-size: calc(var(--bingo-element-size) / 2);

  .tile {
    letter-spacing: 2px;
    color: white;
    font-size: calc(var(--bingo-element-size) * 0.5);
    border-radius: 10%;
    background-color: var(--border);
    transition: all 200ms;
    box-shadow: calc(var(--bingo-element-size) * -0.05) calc(var(--bingo-element-size) * 0.05) 0 0 var(--shadow-1);
    user-select: none;
    text-transform: uppercase;
    padding: calc(var(--bingo-element-size) * 0.5);
    font-weight: bold !important;
    text-align: center;
    appearance: none;
    border: none;

  }
  .header {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(var(--bingo-element-size) * 0.8);
    text-transform: uppercase;
    padding: 0;
  }

  .tile-enabled {
    opacity: 1;
  }
  .tile-disabled {
    opacity: 0.3;
  }

  .position {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .position img {
    border-radius: 50%;
    height: calc(var(--bingo-element-size) * 0.8);
  }
}
.tie-bingo-board {
  --bingo-element-size: 4rem;

  grid-template-columns: repeat(3, var(--bingo-element-size));
  grid-template-rows: var(--bingo-element-size);
  grid-template-areas: 'board board board';
}

.bingo-board-blank {
  user-select: none;
  width: calc(var(--bingo-element-size) * 5+ 4px * 5);
  height: calc(var(--bingo-element-size) * 6 + 5px + 4px * 5);
  margin: 10px;
  justify-content: center;
  align-self: center;
  display: flex;
}

.glow {
  background-color: hsl(57, 62%, 65%) !important;
  box-shadow: -3px 3px 0 0 hsl(57, 62%, 40%) !important;
}

.tie-winner-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
